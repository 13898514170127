import React from 'react';
import card from './snippets/card.js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";


export default class Services extends React.Component {
    render() {
        return (
            <div className="App">
                <div className="container">
                    <div className="pb-1 border-bottom my-5">
                        <h1>Services</h1>
                    </div>
                    <div className="container">

                        <div className={"container"}>
                            <p><a href={"mailto:cfarnworth@gooseworks.net"}><FontAwesomeIcon icon={faEnvelope}
                                                                                             size={"5x"}/></a>
                            </p>
                            {/* contact us */}


                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
