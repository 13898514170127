import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer, faDraftingCompass, faDesktop, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";


export default class Home extends React.Component {
    render() {
        return (
            <div className="App">
                <div className="container">
                    <div className="container px-4 py-5" id="featured-3">
                        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                            <div className="feature col">
                                <FontAwesomeIcon icon={faDraftingCompass} size={'5x'}/>
                                <h2 className={"pt-2"}>System Architecture</h2>
                                <p>Networks built right - every time.</p>
                            </div>
                            <div className="feature col">
                                <FontAwesomeIcon icon={faServer} size={'5x'}/>
                                <h2 className={"pt-2"}>Colocation</h2>
                                <p>Coming soon to West and Midwest US markets!</p>
                            </div>
                            <div className="feature col">
                                <FontAwesomeIcon icon={faDesktop} size={'5x'}/>
                                <h2 className={"pt-2"}>Consulting services</h2>
                                <p>Let's work together to resolve your business's most critical IT pain points and
                                    ensure a successfull long-term IT strategy.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"container"}>
                    <div className="pb-1 border-bottom my-4">
                        <h5>Running and maintaining infrastructure in the modern era has never been more difficult.</h5>
                        <h5>Let Gooseworks help. </h5>
                    </div>
                </div>

                <div className={"container"}>
                    <p><a href={"mailto:cfarnworth@gooseworks.net"}><FontAwesomeIcon icon={faEnvelope} size={"5x"}/></a>
                    </p>
                </div>

            </div>
        );
    }

//
}