import '../App.css';
import './navbar.js';
import './serviceItem.js';
import React from 'react'
// todo: either central login w/ database backend OR splash page
// think on this
export default class ClientPortal extends React.Component {
    render() {
        return (
            <div className="App">
                <p>This is the client portal. Please check back later for access.</p>
            </div>
        );
    }
}